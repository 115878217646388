import { useContext, useState } from "react";
import { toAbsoluteUrl } from "helpers";
import ClickAwayListener from "react-click-away-listener";
// CONTEXT
// COMPONENTS
import DropdownMenu from "./components/DropdownMenu/DropdownMenu";
// STYLE
import styles from "../../Header.module.scss";
import { AppLevelContext } from "context/AppLevelContext";

type ProfileMenuProps = {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (value: boolean) => void;
};

const ProfileMenu = ({
  isDropdownOpen,
  setIsDropdownOpen,
}: ProfileMenuProps) => {
  const { user } = useContext(AppLevelContext);
  const platform = user?.platform?.toLowerCase();

  return (
    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
      <div>
        <div
          className={styles.profileMenu}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <img
            className={styles.profilePicture}
            src={user?.logo || toAbsoluteUrl("/assets/icons/defaultAvatar.png")}
            alt="profile"
            onError={(e) => {
              e.currentTarget.src = toAbsoluteUrl(
                "/assets/icons/defaultAvatar.png"
              );
            }}
          />
          <div className={styles.profileInfo}>
            <span className={styles.name}>{user?.fullname}Name Surname</span>
            <span className={styles.username}>{user?.email}</span>
          </div>
          <img
            src={toAbsoluteUrl("/assets/icons/Header/downArrow.png")}
            alt="arrow"
          />
        </div>
        {isDropdownOpen && (
          <DropdownMenu
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default ProfileMenu;
