// CSS Modules
import styles from "../Sidebar.module.scss";
// NAV ROUTES
import { navRoutes } from "../navRoutes";
// COMPONENTS
import SingleNavigation from "./components/SingleNavigation";

interface NavigationProps {
  isSidebarMinified: boolean;
}

const Navigation = ({ isSidebarMinified }: NavigationProps) => {
  return (
    <div className={styles.menu} data-testid="sidebar-navigation">
      {navRoutes.map((route, index) => (
        <SingleNavigation
          key={index}
          route={route}
          isSidebarMinified={isSidebarMinified}
        />
      ))}
    </div>
  );
};

export default Navigation;
