import Login from "pages/Login/Login";
import Authorization from "pages/Authorization/Authorization";
import PrivateRoutes from "./PrivateRoutes";
//PAGES
import Dashboard from "pages/Dashboard/Dashboard";
import Appointments from "pages/Appointments/Appointments";
import Calendar from "pages/Calendar/Calendar";
import Reviews from "pages/Reviews/Reviews";
import Customers from "pages/Customers/Customers";
import Settings from "pages/Settings/Settings";

export const routesPaths = [
  // PAGES
  {
    path: "/",
    element: <Dashboard />,
    auth: true,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    auth: true,
  },
  {
    path: "/appointments",
    element: <Appointments />,
    auth: true,
  },
  {
    path: "/calendar",
    element: <Calendar />,
    auth: true,
  },
  {
    path: "/reviews",
    element: <Reviews />,
    auth: true,
  },
  {
    path: "/customers",
    element: <Customers />,
    auth: true,
  },
  {
    path: "/settings",
    element: <Settings />,
    auth: true,
  },
  {
    path: "/auth",
    element: <Authorization />,
    auth: false,
  },
  {
    path: "*",
    element: (
      <>
        <h1>404</h1>
        <p>Page not found</p>
      </>
    ),
    auth: true,
  },
];

const authCheck = (routes) =>
  routes.map((route) => {
    if (route?.auth) {
      route.element = <PrivateRoutes>{route.element}</PrivateRoutes>;
    }
    return route;
  });

export default authCheck(routesPaths);
