import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
// TOASTIFY
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./MasterLayout.module.scss";
// COMPONENTS
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
// CONTEXT
import Loading from "components/Shared/Loading/Loading";
import { AppLevelContext } from "context/AppLevelContext";
import ClickAwayListener from "react-click-away-listener";

interface MasterLayoutProps {
  children: React.ReactNode;
}

const MasterLayout = ({ children }: MasterLayoutProps) => {
  const location = useLocation();
  const { globalLoading, user } = useContext(AppLevelContext);
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [isSidebarMinified, setSidebarMinified] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("auth")) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem("isSidebarMinified")) {
      setSidebarMinified(
        JSON.parse(localStorage.getItem("isSidebarMinified") as string)
      );
    }
  }, []);

  if (globalLoading && !user) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }
  return (
    <div className={styles.masterLayout}>
      <ToastContainer pauseOnHover={false} theme={"dark"} />
      {showNav && (
        <Sidebar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          isSidebarMinified={isSidebarMinified}
          setSidebarMinified={setSidebarMinified}
        />
      )}

      <div
        className={styles.content}
        style={{
          width: showNav && isSidebarMinified ? "calc(100% - 80px)" : undefined,
        }}
      >
        {showNav && (
          <Header
            sidebarToggle={sidebarToggle}
            setSidebarToggle={setSidebarToggle}
          />
        )}
        <div className={styles.contentContainer}>{children}</div>
      </div>
    </div>
  );
};

export default MasterLayout;
