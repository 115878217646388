import { AppLevelContext } from "context/AppLevelContext";
import { useContext, useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";

// CONTEXT

interface PrivateRoutesProps {
  children: React.ReactNode;
}

const PrivateRoutes = ({ children }: PrivateRoutesProps) => {
  const location = useLocation();
  const { user } = useContext(AppLevelContext);
  const params = useParams();

  useEffect(() => {
    if (params.profilePath) {
      sessionStorage.setItem("profilePath", params.profilePath);
    }
  }, [params]);

  if (!user) {
    return (
      <Navigate
        to="/auth"
        state={{ return_url: location.pathname + location.search }}
        replace={true}
      />
    );
  } else {
    return children;
  }
};

export default PrivateRoutes;
