export const navList = [
  {
    title: "Terms And Privacy",
    icon: "termsAndPrivacy",
    path: "/terms-and-privacy-policy",
  },
  {
    title: "Settings",
    icon: "settings",
    path: "/settings",
  },
  {
    title: "Logout",
    icon: "logout",
    path: null,
  },
];
