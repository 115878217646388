import styles from "./ClientSwitcher.module.scss";
import { Tooltip } from "@mui/material";

interface ClientSwitcherProps {
  isSidebarMinified: boolean;
}

const ClientSwitcher = (props: ClientSwitcherProps) => {
  return (
    <div
      className={styles.clientSwitcher}
      style={
        props.isSidebarMinified
          ? {
              padding: "6px",
              alignItems: "center",
              justifyContent: "center",
            }
          : undefined
      }
    >
      <div
        className={styles.brandLogo}
        style={
          props.isSidebarMinified
            ? {
                width: "36px",
                height: "36px",
                minWidth: "unset",
                minHeight: "unset",
              }
            : undefined
        }
      >
        <Tooltip
          title="Kadir Alkan Yahyakaptan"
          arrow
          placement="right"
          disableHoverListener={props.isSidebarMinified ? false : true}
        >
          <img
            src="https://www.kadiralkan.com.tr/wp-content/uploads/2021/01/WhatsApp-Image-2022-03-01-at-23.40.58.jpeg"
            alt="Brand"
          />
        </Tooltip>
      </div>
      <div
        className={styles.brandDesc}
        style={props.isSidebarMinified ? { display: "none" } : undefined}
      >
        <div className={styles.brandTitle}>Kadir Alkan Yahyakaptan</div>
        <div className={styles.brandLocation}>Kocaeli / İzmit</div>
      </div>
    </div>
  );
};

export default ClientSwitcher;
