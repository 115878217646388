import {
  DataGridPro,
  GridRowsProp,
  DataGridProProps,
} from "@mui/x-data-grid-pro";
import { ThemeProvider, createTheme } from "@mui/material/styles";
//STYLES
import styles from "./CustomDataGrid.module.scss";

//REMOVE THIS CODE AFTER IMPLEMENTING THE DATA GRID
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

interface CustomDataGridProps {
  view: DataGridProProps;
  rows: GridRowsProp;
  handleFetchData: (newPaginationModel: any) => void;
  isLoading: boolean;
  paginationModel: any;
  rowCountState: number;
}

const CustomDataGrid: React.FC<CustomDataGridProps> = ({
  view,
  rows,
  handleFetchData,
  isLoading,
  paginationModel,
  rowCountState,
}) => {
  return (
    <div className={styles.dataGrid}>
      <ThemeProvider theme={darkTheme}>
        <DataGridPro
          {...view}
          rows={rows}
          columns={view.columns}
          sx={{
            "& .MuiDataGrid-cell": {
              cursor: "pointer",
            },
          }}
          disableColumnPinning={view.disableColumnPinning ?? false}
          rowHeight={view.rowHeight ?? 52}
          initialState={view.initialState}
          density={view.density ?? "standard"}
          getDetailPanelContent={view.getDetailPanelContent ?? undefined}
          getDetailPanelHeight={view.getDetailPanelHeight ?? undefined}
          rowCount={rowCountState}
          loading={isLoading}
          pagination={true}
          pageSizeOptions={[10]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handleFetchData}
          disableRowSelectionOnClick={true}
        />
      </ThemeProvider>
    </div>
  );
};

export default CustomDataGrid;
