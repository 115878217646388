// LAYOUTS
import MasterLayout from 'layouts/MasterLayout'
//ROUTES
import {useRoutes} from 'react-router-dom'
import routesPath from 'routes/routes.js'
const App = () => {
  const routing = useRoutes(routesPath)
  return <MasterLayout>{routing}</MasterLayout>
  
}

export default App
