import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
// Context
// Router
import { BrowserRouter as Router } from "react-router-dom";
import { AppLevelContextProvider } from "context/AppLevelContext";

// Capture the initial referrer when the application first loads
const initialReferrer = document.referrer;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <Router>
      <AppLevelContextProvider>
        <App />
      </AppLevelContextProvider>
    </Router>
  </>
);

// Export the initialReferrer so it can be used elsewhere, like in your analytics.js
export { initialReferrer };
