import styles from "./Sidebar.module.scss";
import headerStyles from "../Header/Header.module.scss";
import { toAbsoluteUrl } from "helpers";
import classNames from "classnames";
import ClickAwayListener from "react-click-away-listener";
import { useState } from "react";
// COMPONENTS
import Navigation from "./Navigation/Navigation";
import ClientSwitcher from "./ClientSwitcher/ClientSwitcher";

type SidebarProps = {
  sidebarToggle: boolean;
  setSidebarToggle: (value: boolean) => void;
  isSidebarMinified: boolean;
  setSidebarMinified: (value: boolean) => void;
};

const Sidebar = ({
  sidebarToggle,
  setSidebarToggle,
  isSidebarMinified,
  setSidebarMinified,
}: SidebarProps) => {
  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (
          !(
            e.target instanceof HTMLElement &&
            e.target.classList.contains(headerStyles.hamburgerIcon)
          )
        ) {
          setSidebarToggle(false);
        }
      }}
    >
      <div
        className={classNames(styles.sidebar, {
          [styles.active]: sidebarToggle,
        })}
        style={{
          width: isSidebarMinified ? "80px" : undefined,
          minWidth: isSidebarMinified ? "80px" : undefined,
        }}
      >
        <div className={styles.logoSection}>
          {isSidebarMinified ? (
            <>
              <img
                className={classNames(styles.logo, styles.logoMark)}
                src={toAbsoluteUrl("/assets/icons/favicon.png")}
                alt="Logo"
                style={
                  isSidebarMinified
                    ? { width: "36px", height: "36px" }
                    : undefined
                }
              />
              <svg
                className={(styles.logo, styles.hiddenLogo)}
                width={519}
                height={129}
                viewBox="0 0 519 129"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {" "}
                <path
                  d="M102.968 20.496C127.002 20.496 146.4 40.016 146.4 64.05V128.1H118.95V64.05C118.95 55.144 111.874 48.068 102.968 48.068C94.184 48.068 86.986 55.144 86.986 64.05V128.1H59.536V64.05C59.536 55.144 52.338 48.068 43.432 48.068C34.648 48.068 27.45 55.144 27.45 64.05V128.1H0V64.05C0 40.016 19.52 20.496 43.432 20.496C55.022 20.496 65.392 25.01 73.2 32.33C81.008 25.01 91.5 20.496 102.968 20.496Z"
                  fill="white"
                />{" "}
                <path
                  d="M311.328 22.814C318.77 18.666 327.188 16.47 336.338 16.47C337.07 16.47 337.802 16.47 338.534 16.47V43.798C337.802 43.676 337.07 43.676 336.338 43.676C322.43 43.676 311.328 54.9 311.328 68.686V127.856H284V68.686C284 48.922 294.98 31.598 311.328 22.814Z"
                  fill="white"
                />{" "}
                <path
                  d="M424.373 75.518V20.984H451.579V75.518C451.579 95.404 440.599 112.606 424.373 121.512C417.297 125.294 409.123 127.612 400.583 127.856C400.461 127.856 400.461 127.856 400.339 127.856C400.095 127.856 399.729 127.856 399.363 127.856C398.997 127.856 398.631 127.856 398.265 127.856C398.143 127.856 398.143 127.856 398.143 127.856C389.481 127.612 381.429 125.294 374.353 121.512C358.005 112.606 347.025 95.404 347.025 75.518V20.984H374.353V75.518C374.353 88.572 384.357 99.308 397.045 100.406C397.289 100.406 397.411 100.528 397.655 100.528C397.777 100.528 398.021 100.528 398.143 100.528C398.265 100.528 398.265 100.528 398.265 100.528C398.509 100.528 398.631 100.528 398.753 100.528C398.875 100.528 399.119 100.528 399.363 100.528C399.485 100.528 399.729 100.528 399.973 100.528C400.095 100.528 400.217 100.528 400.339 100.528H400.461C400.705 100.528 400.827 100.528 401.071 100.528C401.193 100.528 401.437 100.406 401.559 100.406C414.369 99.308 424.373 88.572 424.373 75.518Z"
                  fill="white"
                />{" "}
                <path
                  d="M491.233 75.518C491.233 89.426 502.457 100.65 516.365 100.65C517.219 100.65 517.951 100.65 518.683 100.528V128.1C517.951 128.1 517.219 128.1 516.365 128.1C507.337 128.1 498.675 125.782 491.233 121.756C474.885 112.728 463.783 95.404 463.783 75.518V0H491.233V75.518Z"
                  fill="white"
                />{" "}
                <path
                  d="M247.496 28.334V20.366H273.06V128.1H247.496V120.132C239.362 125.112 229.568 128.1 219.276 128.1H208.984C179.27 128.1 155.2 104.03 155.2 74.316C155.2 44.436 179.27 20.366 208.984 20.366H219.276C229.568 20.366 239.362 23.354 247.496 28.334ZM247.496 74.316C247.496 58.712 234.88 46.096 219.276 46.096H208.984C193.546 46.096 180.764 58.712 180.764 74.316C180.764 89.754 193.546 102.37 208.984 102.37H219.276C234.88 102.37 247.496 89.754 247.496 74.316Z"
                  fill="white"
                />{" "}
              </svg>
            </>
          ) : (
            <svg
              className={styles.logo}
              width={519}
              height={129}
              viewBox="0 0 519 129"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {" "}
              <path
                d="M102.968 20.496C127.002 20.496 146.4 40.016 146.4 64.05V128.1H118.95V64.05C118.95 55.144 111.874 48.068 102.968 48.068C94.184 48.068 86.986 55.144 86.986 64.05V128.1H59.536V64.05C59.536 55.144 52.338 48.068 43.432 48.068C34.648 48.068 27.45 55.144 27.45 64.05V128.1H0V64.05C0 40.016 19.52 20.496 43.432 20.496C55.022 20.496 65.392 25.01 73.2 32.33C81.008 25.01 91.5 20.496 102.968 20.496Z"
                fill="white"
              />{" "}
              <path
                d="M311.328 22.814C318.77 18.666 327.188 16.47 336.338 16.47C337.07 16.47 337.802 16.47 338.534 16.47V43.798C337.802 43.676 337.07 43.676 336.338 43.676C322.43 43.676 311.328 54.9 311.328 68.686V127.856H284V68.686C284 48.922 294.98 31.598 311.328 22.814Z"
                fill="white"
              />{" "}
              <path
                d="M424.373 75.518V20.984H451.579V75.518C451.579 95.404 440.599 112.606 424.373 121.512C417.297 125.294 409.123 127.612 400.583 127.856C400.461 127.856 400.461 127.856 400.339 127.856C400.095 127.856 399.729 127.856 399.363 127.856C398.997 127.856 398.631 127.856 398.265 127.856C398.143 127.856 398.143 127.856 398.143 127.856C389.481 127.612 381.429 125.294 374.353 121.512C358.005 112.606 347.025 95.404 347.025 75.518V20.984H374.353V75.518C374.353 88.572 384.357 99.308 397.045 100.406C397.289 100.406 397.411 100.528 397.655 100.528C397.777 100.528 398.021 100.528 398.143 100.528C398.265 100.528 398.265 100.528 398.265 100.528C398.509 100.528 398.631 100.528 398.753 100.528C398.875 100.528 399.119 100.528 399.363 100.528C399.485 100.528 399.729 100.528 399.973 100.528C400.095 100.528 400.217 100.528 400.339 100.528H400.461C400.705 100.528 400.827 100.528 401.071 100.528C401.193 100.528 401.437 100.406 401.559 100.406C414.369 99.308 424.373 88.572 424.373 75.518Z"
                fill="white"
              />{" "}
              <path
                d="M491.233 75.518C491.233 89.426 502.457 100.65 516.365 100.65C517.219 100.65 517.951 100.65 518.683 100.528V128.1C517.951 128.1 517.219 128.1 516.365 128.1C507.337 128.1 498.675 125.782 491.233 121.756C474.885 112.728 463.783 95.404 463.783 75.518V0H491.233V75.518Z"
                fill="white"
              />{" "}
              <path
                d="M247.496 28.334V20.366H273.06V128.1H247.496V120.132C239.362 125.112 229.568 128.1 219.276 128.1H208.984C179.27 128.1 155.2 104.03 155.2 74.316C155.2 44.436 179.27 20.366 208.984 20.366H219.276C229.568 20.366 239.362 23.354 247.496 28.334ZM247.496 74.316C247.496 58.712 234.88 46.096 219.276 46.096H208.984C193.546 46.096 180.764 58.712 180.764 74.316C180.764 89.754 193.546 102.37 208.984 102.37H219.276C234.88 102.37 247.496 89.754 247.496 74.316Z"
                fill="white"
              />{" "}
            </svg>
          )}

          <button
            className={classNames(styles.toggleSidebarSize, {
              [styles.active]: isSidebarMinified,
            })}
            onClick={() => {
              setSidebarMinified(!isSidebarMinified);
              localStorage.setItem(
                "isSidebarMinified",
                JSON.stringify(!isSidebarMinified)
              );
            }}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth={0}
              viewBox="0 0 20 20"
              aria-hidden="true"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.72 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 1 0 1.06-1.06L6.31 10l3.72-3.72a.75.75 0 1 0-1.06-1.06L4.72 9.47Zm9.25-4.25L9.72 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 1 0 1.06-1.06L11.31 10l3.72-3.72a.75.75 0 0 0-1.06-1.06Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className={styles.close} onClick={() => setSidebarToggle(false)}>
          <img src={toAbsoluteUrl("/assets/icons/closeIcon.png")} alt="" />
        </div>
        <Navigation isSidebarMinified={isSidebarMinified} />
        <ClientSwitcher isSidebarMinified={isSidebarMinified} />
      </div>
    </ClickAwayListener>
  );
};

export default Sidebar;
