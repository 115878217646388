import { toAbsoluteUrl } from "helpers";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// CONTEXT
import { AppLevelContext } from "context/AppLevelContext";
// STYLES
import styles from "../DropdownMenu/DropdownMenu.module.scss";
// NAV ROUTES
import { navList } from "./navList";

const DropdownNav = () => {
  const { logout } = useContext(AppLevelContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {navList.map((item, index) => (
        <div
          className={styles.dropdownMenuItem}
          key={index}
          onClick={() => {
            if (item.title === "Logout") {
              logout();
            } else {
              if (item.path) {
                navigate(item.path);
              }
            }
          }}
        >
          <img
            src={toAbsoluteUrl(`/assets/icons/Header/${item.icon}.png`)}
            alt="icon"
          />

          {(item.path === null && item.title) || item.title}
        </div>
      ))}
    </>
  );
};

export default DropdownNav;
