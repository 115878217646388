import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import styles from "../../Sidebar.module.scss";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

type SingleNavigationProps = {
  route: {
    name: string;
    path: string;
    icon: string;
    beta: boolean;
    new: boolean;
  };
  isSidebarMinified: boolean;
};

const SingleNavigation = ({
  route,
  isSidebarMinified,
}: SingleNavigationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  let isActive = location.pathname === route.path;
  if (route.path === "/ads" && location.pathname === "/") {
    isActive = true;
  }

  return (
    <Tooltip
      title={route.name}
      arrow
      disableHoverListener={isSidebarMinified ? false : true}
      placement="right"
    >
      <div
        className={classNames(styles.menuItem, { [styles.active]: isActive })}
        onClick={() => {
          navigate(route.path);
        }}
        style={{
          justifyContent: isSidebarMinified ? "center" : undefined,
        }}
      >
        <div
          className={styles.icon}
          dangerouslySetInnerHTML={{ __html: route.icon }}
        />
        <div
          className={styles.sideBarItem}
          style={{
            display: isSidebarMinified ? "none" : undefined,
          }}
        >
          {route.name}
          {route.beta && <span className={styles.beta}>beta</span>}
          {route.new && <span className={styles.new}>new</span>}
        </div>
      </div>
    </Tooltip>
  );
};

export default SingleNavigation;
