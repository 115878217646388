import StatusCircle from "../StatusCircle/StatusCircle";
import styles from "./CustomComponentCell.module.scss";

type Props = {
  value: string;
  image?: {
    rounded: "rounded" | "standard" | "circle";
    value: string;
  };
  status?: "active" | "draft" | "pending";
};

const CustomComponentCell: React.FC<Props> = ({ value, image, status }) => {
  return (
    <div className={styles.customComponentCell}>
      {status ? <StatusCircle status={status} /> : null}
      {image ? (
        <img
          src={image.value}
          alt={"cell-icon"}
          className={
            image.rounded === "rounded"
              ? styles.roundedDataIcon
              : image.rounded === "circle"
              ? styles.circleDataIcon
              : styles.dataIcon
          }
        />
      ) : null}
      {value}
    </div>
  );
};

export default CustomComponentCell;
