import { useState, useEffect } from "react";
//STYLES
import styles from "./Dashboard.module.scss";

//COMPONENTS
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";

import { campaignViews } from "components/CustomDataGrid/views";

const Dashboard = () => {
  document.title = "Dashboard" + " - Marul";
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [pageInfo, setPageInfo] = useState({ totalRowCount: [].length });
  const [rowCountState, setRowCountState] = useState(
    pageInfo?.totalRowCount || 0
  );

  const handleFetchData = async (newPaginationModel: any) => {
    setIsLoading(true);
    setPaginationModel(newPaginationModel);
    console.log("paginationModel", newPaginationModel);
    console.log("fetch data");
    console.log(
      "offset: ",
      newPaginationModel.page * newPaginationModel.pageSize
    );
    console.log("limit: ", newPaginationModel.pageSize);
    setIsLoading(false);
    // try{
    //   const response = await getAds(pagination: {offset: newPaginationModel.page * newPaginationModel.pageSize, limit: newPaginationModel.pageSize});
    //   setRowCountState(response?.totalRowCount);
    //   setData(response?.data);
    //   setIsLoading(false);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined
        ? pageInfo?.totalRowCount
        : prevRowCountState
    );
  }, [pageInfo?.totalRowCount, setRowCountState]);

  return (
    <div className={styles.ads}>
      <CustomDataGrid
        view={campaignViews.targetAudienceView}
        rows={[]}
        handleFetchData={handleFetchData}
        isLoading={isLoading}
        paginationModel={paginationModel}
        rowCountState={rowCountState}
      />
    </div>
  );
};

export default Dashboard;
