import { useRef, useState, useContext, useEffect } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

//STYLES
import styles from "./Authorization.module.scss";

//CONTEXTS
import { AppLevelContext } from "context/AppLevelContext";

//COMPONENTS
import Loading from "components/Shared/Loading/Loading";

interface SignInData {
  email: string;
  password: string;
}

interface SignUpData extends SignInData {
  confirmPassword: string;
}

type CurrentView = "sign-in" | "sign-up" | "forgot-password";

interface Testimonials {
  text: string;
  photo: string;
  name: string;
  designation: string;
}

const Authorization = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [signInData, setSignInData] = useState<SignInData>({} as SignInData);
  const [signUpData, setSignUpData] = useState<SignUpData>({} as SignUpData);
  const [currentView, setCurrentView] = useState<CurrentView>("sign-in");
  const signInPasswordInput = useRef<HTMLInputElement>(null);
  const signUpPasswordInput = useRef<HTMLInputElement>(null);
  const signUpConfirmPasswordInput = useRef<HTMLInputElement>(null);
  const { signIn, signUp, resetPassword, user } = useContext(AppLevelContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [testimonialIndex, setTestimonialIndex] = useState(0);

  const testimonials: Testimonials[] = [
    {
      text: "Marul has helped me to grow my business and connect with customers in a way that I never thought was possible!",
      photo:
        "https://www.menhane.com/images/kategoriler/Kadir-Alkan-Serisi-resim-148.jpg",
      name: "Kadir Alkan",
      designation: "Founder, Kadir Alkan Hair Artist",
    },
    {
      text: "Thanks to Marul, we rapidly grew our business and increased customer satisfaction.",
      photo: "https://randomuser.me/api/portraits/women/90.jpg",
      name: "Jasmine Yılmaz",
      designation: "CEO, Yılmaz Hairdresser",
    },
    {
      text: "With Marul, we expanded our customer base and significantly boosted our sales.",
      photo: "https://randomuser.me/api/portraits/men/9.jpg",
      name: "Tolga Sarıtaş",
      designation: "Social Media Manager, Demir Barber",
    },
    {
      text: "The solutions provided by Marul helped us optimize our business processes and increase efficiency.",
      photo: "https://randomuser.me/api/portraits/women/68.jpg",
      name: "Gülçin Kılıç",
      designation: "Operations Director, Harlem Beauty",
    },
    {
      text: "Marul's effective customer management tools have strengthened our customer relationships.",
      photo: "https://randomuser.me/api/portraits/men/66.jpg",
      name: "Emre Arslan",
      designation: "Manager, Altın Ustura",
    },
  ];

  const handleSignIn = async () => {
    if (!signInData.email || !signInData.password)
      return toast.error("Please fill all fields");
    setLoading(true);
    const response = await signIn(signInData.email, signInData.password);
    if (!response.success) toast.error(response.error);
    setLoading(false);
  };

  const handleSignUp = async () => {
    if (
      !signUpData.email ||
      !signUpData.password ||
      !signUpData.confirmPassword
    )
      return toast.error("Please fill all fields");
    if (signUpData.password.length < 6)
      return toast.error("Password must be at least 6 characters");
    if (signUpData.password !== signUpData.confirmPassword)
      return toast.error("Passwords do not match");
    setLoading(true);
    const response = await signUp(signUpData.email, signUpData.password);
    if (!response.success) toast.error(response.error);
    setLoading(false);
  };

  const handleForgotPassword = async () => {
    if (!signInData.email) return toast.error("Please fill all fields");
    setLoading(true);
    const response = await resetPassword(signInData.email);
    if (!response.success) {
      toast.error(response.error);
    } else {
      toast.success(
        "If there is an account with this email, a reset link has been sent."
      );
    }
    setLoading(false);
  };
  //TESTIMONIAL SLIDER
  useEffect(() => {
    console.log("useEffect");
    setInterval(() => {
      setTestimonialIndex((prev) => {
        if (prev === testimonials.length - 1) return 0;
        return prev + 1;
      });
    }, 5000);
  }, []);

  if (user) {
    return navigate("/");
  }

  return (
    <div className={styles.authBody}>
      <div className={styles.authContent}>
        <div className={styles.slideSection}>
          <div className={styles.logoSection}>
            <svg
              className={styles.logo}
              width={519}
              height={129}
              viewBox="0 0 519 129"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {" "}
              <path
                d="M102.968 20.496C127.002 20.496 146.4 40.016 146.4 64.05V128.1H118.95V64.05C118.95 55.144 111.874 48.068 102.968 48.068C94.184 48.068 86.986 55.144 86.986 64.05V128.1H59.536V64.05C59.536 55.144 52.338 48.068 43.432 48.068C34.648 48.068 27.45 55.144 27.45 64.05V128.1H0V64.05C0 40.016 19.52 20.496 43.432 20.496C55.022 20.496 65.392 25.01 73.2 32.33C81.008 25.01 91.5 20.496 102.968 20.496Z"
                fill="white"
              />{" "}
              <path
                d="M311.328 22.814C318.77 18.666 327.188 16.47 336.338 16.47C337.07 16.47 337.802 16.47 338.534 16.47V43.798C337.802 43.676 337.07 43.676 336.338 43.676C322.43 43.676 311.328 54.9 311.328 68.686V127.856H284V68.686C284 48.922 294.98 31.598 311.328 22.814Z"
                fill="white"
              />{" "}
              <path
                d="M424.373 75.518V20.984H451.579V75.518C451.579 95.404 440.599 112.606 424.373 121.512C417.297 125.294 409.123 127.612 400.583 127.856C400.461 127.856 400.461 127.856 400.339 127.856C400.095 127.856 399.729 127.856 399.363 127.856C398.997 127.856 398.631 127.856 398.265 127.856C398.143 127.856 398.143 127.856 398.143 127.856C389.481 127.612 381.429 125.294 374.353 121.512C358.005 112.606 347.025 95.404 347.025 75.518V20.984H374.353V75.518C374.353 88.572 384.357 99.308 397.045 100.406C397.289 100.406 397.411 100.528 397.655 100.528C397.777 100.528 398.021 100.528 398.143 100.528C398.265 100.528 398.265 100.528 398.265 100.528C398.509 100.528 398.631 100.528 398.753 100.528C398.875 100.528 399.119 100.528 399.363 100.528C399.485 100.528 399.729 100.528 399.973 100.528C400.095 100.528 400.217 100.528 400.339 100.528H400.461C400.705 100.528 400.827 100.528 401.071 100.528C401.193 100.528 401.437 100.406 401.559 100.406C414.369 99.308 424.373 88.572 424.373 75.518Z"
                fill="white"
              />{" "}
              <path
                d="M491.233 75.518C491.233 89.426 502.457 100.65 516.365 100.65C517.219 100.65 517.951 100.65 518.683 100.528V128.1C517.951 128.1 517.219 128.1 516.365 128.1C507.337 128.1 498.675 125.782 491.233 121.756C474.885 112.728 463.783 95.404 463.783 75.518V0H491.233V75.518Z"
                fill="white"
              />{" "}
              <path
                d="M247.496 28.334V20.366H273.06V128.1H247.496V120.132C239.362 125.112 229.568 128.1 219.276 128.1H208.984C179.27 128.1 155.2 104.03 155.2 74.316C155.2 44.436 179.27 20.366 208.984 20.366H219.276C229.568 20.366 239.362 23.354 247.496 28.334ZM247.496 74.316C247.496 58.712 234.88 46.096 219.276 46.096H208.984C193.546 46.096 180.764 58.712 180.764 74.316C180.764 89.754 193.546 102.37 208.984 102.37H219.276C234.88 102.37 247.496 89.754 247.496 74.316Z"
                fill="white"
              />{" "}
            </svg>
          </div>
          <div className={styles.slideText}>
            <div className={styles.title}>
              Start your <br></br>journey with us.
            </div>
            <div className={styles.subTitle}>
              Connect with customers and grow your business through Marul.
            </div>
          </div>
          <div className={styles.customerQuotes}>
            <div className={styles.quote}>
              <div className={styles.quoteText}>
                {testimonials[testimonialIndex].text}
              </div>
              <div className={styles.quoteAuthor}>
                <div className={styles.quoteAuthorImage}>
                  <img
                    src={testimonials[testimonialIndex].photo}
                    alt={testimonials[testimonialIndex].name}
                  />
                </div>
                <div className={styles.quoteAuthorDetail}>
                  <div className={styles.quoteAuthorName}>
                    {testimonials[testimonialIndex].name}
                  </div>
                  <div className={styles.quoteAuthorDesignation}>
                    {testimonials[testimonialIndex].designation}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.quoteDots}>
              {testimonials.map((testimonial, index) => (
                <div
                  className={classNames(styles.quoteDot, {
                    [styles.active]: index === testimonialIndex,
                  })}
                  onClick={() => setTestimonialIndex(index)}
                ></div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.formSection}>
          <div className={styles.formTitle}>
            <div className={styles.mobileLogo}>
              <svg
                className={styles.logo}
                width={519}
                height={129}
                viewBox="0 0 519 129"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {" "}
                <path
                  d="M102.968 20.496C127.002 20.496 146.4 40.016 146.4 64.05V128.1H118.95V64.05C118.95 55.144 111.874 48.068 102.968 48.068C94.184 48.068 86.986 55.144 86.986 64.05V128.1H59.536V64.05C59.536 55.144 52.338 48.068 43.432 48.068C34.648 48.068 27.45 55.144 27.45 64.05V128.1H0V64.05C0 40.016 19.52 20.496 43.432 20.496C55.022 20.496 65.392 25.01 73.2 32.33C81.008 25.01 91.5 20.496 102.968 20.496Z"
                  fill="white"
                />{" "}
                <path
                  d="M311.328 22.814C318.77 18.666 327.188 16.47 336.338 16.47C337.07 16.47 337.802 16.47 338.534 16.47V43.798C337.802 43.676 337.07 43.676 336.338 43.676C322.43 43.676 311.328 54.9 311.328 68.686V127.856H284V68.686C284 48.922 294.98 31.598 311.328 22.814Z"
                  fill="white"
                />{" "}
                <path
                  d="M424.373 75.518V20.984H451.579V75.518C451.579 95.404 440.599 112.606 424.373 121.512C417.297 125.294 409.123 127.612 400.583 127.856C400.461 127.856 400.461 127.856 400.339 127.856C400.095 127.856 399.729 127.856 399.363 127.856C398.997 127.856 398.631 127.856 398.265 127.856C398.143 127.856 398.143 127.856 398.143 127.856C389.481 127.612 381.429 125.294 374.353 121.512C358.005 112.606 347.025 95.404 347.025 75.518V20.984H374.353V75.518C374.353 88.572 384.357 99.308 397.045 100.406C397.289 100.406 397.411 100.528 397.655 100.528C397.777 100.528 398.021 100.528 398.143 100.528C398.265 100.528 398.265 100.528 398.265 100.528C398.509 100.528 398.631 100.528 398.753 100.528C398.875 100.528 399.119 100.528 399.363 100.528C399.485 100.528 399.729 100.528 399.973 100.528C400.095 100.528 400.217 100.528 400.339 100.528H400.461C400.705 100.528 400.827 100.528 401.071 100.528C401.193 100.528 401.437 100.406 401.559 100.406C414.369 99.308 424.373 88.572 424.373 75.518Z"
                  fill="white"
                />{" "}
                <path
                  d="M491.233 75.518C491.233 89.426 502.457 100.65 516.365 100.65C517.219 100.65 517.951 100.65 518.683 100.528V128.1C517.951 128.1 517.219 128.1 516.365 128.1C507.337 128.1 498.675 125.782 491.233 121.756C474.885 112.728 463.783 95.404 463.783 75.518V0H491.233V75.518Z"
                  fill="white"
                />{" "}
                <path
                  d="M247.496 28.334V20.366H273.06V128.1H247.496V120.132C239.362 125.112 229.568 128.1 219.276 128.1H208.984C179.27 128.1 155.2 104.03 155.2 74.316C155.2 44.436 179.27 20.366 208.984 20.366H219.276C229.568 20.366 239.362 23.354 247.496 28.334ZM247.496 74.316C247.496 58.712 234.88 46.096 219.276 46.096H208.984C193.546 46.096 180.764 58.712 180.764 74.316C180.764 89.754 193.546 102.37 208.984 102.37H219.276C234.88 102.37 247.496 89.754 247.496 74.316Z"
                  fill="white"
                />{" "}
              </svg>
            </div>
            <div className={styles.title}>
              {currentView === "sign-in"
                ? "Sign in"
                : currentView === "sign-up"
                ? "Sign up"
                : "Reset Password"}
            </div>
            <div className={styles.subTitle}>
              {currentView === "sign-in"
                ? "Welcome back! Please sign in to your account."
                : currentView === "sign-up"
                ? "Create your business account and connect with customers."
                : currentView === "forgot-password"
                ? "Enter your e-mail to reset your password."
                : undefined}
            </div>
          </div>
          <div className={styles.form}>
            {currentView === "sign-in" && (
              <>
                <div className={styles.formItem}>
                  <div className={styles.title}>E-Mail</div>
                  <input
                    type="email"
                    placeholder="Enter your e-mail"
                    value={signInData?.email}
                    onChange={(e) => {
                      setSignInData({ ...signInData, email: e.target.value });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        signInPasswordInput.current?.focus();
                      }
                    }}
                    tabIndex={1}
                  />
                </div>
                <div className={styles.formItem}>
                  <div className={styles.title}>Password</div>
                  <div className={styles.inputWrapper}>
                    <input
                      type={isShowPassword ? "text" : "password"}
                      ref={signInPasswordInput}
                      placeholder="Enter your password"
                      value={signInData?.password}
                      onChange={(e) => {
                        setSignInData({
                          ...signInData,
                          password: e.target.value,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSignIn();
                        }
                      }}
                      tabIndex={2}
                    />
                    <button
                      className={classNames(styles.showHideBtn, {
                        [styles.show]: isShowPassword,
                      })}
                      onClick={() => setIsShowPassword(!isShowPassword)}
                    >
                      {isShowPassword ? (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 576 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                        </svg>
                      ) : (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 640 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
                <div className={styles.formItem}>
                  <button
                    className={styles.forgotPasswordBtn}
                    onClick={() => setCurrentView("forgot-password")}
                  >
                    Forgot Password?
                  </button>
                </div>
                <div className={styles.formItem}>
                  <button
                    className={styles.signBtn}
                    onClick={handleSignIn}
                    disabled={loading}
                    tabIndex={3}
                  >
                    {loading ? (
                      <Loading height="2" width="2" color="#fafafa" />
                    ) : (
                      "Sign In"
                    )}
                  </button>
                </div>
              </>
            )}
            {currentView === "sign-up" && (
              <>
                <div className={styles.formItem}>
                  <div className={styles.title}>E-Mail</div>
                  <input
                    type="email"
                    placeholder="Enter your e-mail"
                    value={signUpData?.email}
                    onChange={(e) =>
                      setSignUpData({ ...signUpData, email: e.target.value })
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        signUpPasswordInput.current?.focus();
                      }
                    }}
                    tabIndex={1}
                  />
                </div>
                <div className={styles.formItem}>
                  <div className={styles.title}>Password</div>
                  <div className={styles.inputWrapper}>
                    <input
                      type={isShowPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      value={signUpData?.password}
                      onChange={(e) =>
                        setSignUpData({
                          ...signUpData,
                          password: e.target.value,
                        })
                      }
                      ref={signUpPasswordInput}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          signUpConfirmPasswordInput.current?.focus();
                        }
                      }}
                      tabIndex={2}
                    />
                    <button
                      className={classNames(styles.showHideBtn, {
                        [styles.show]: isShowPassword,
                      })}
                      onClick={() => setIsShowPassword(!isShowPassword)}
                    >
                      {isShowPassword ? (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 576 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                        </svg>
                      ) : (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 640 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
                <div className={styles.formItem}>
                  <div className={styles.title}>Confirm Password</div>
                  <div className={styles.inputWrapper}>
                    <input
                      type={isShowPassword ? "text" : "password"}
                      placeholder="Confirm your password"
                      value={signUpData?.confirmPassword}
                      ref={signUpConfirmPasswordInput}
                      onChange={(e) =>
                        setSignUpData({
                          ...signUpData,
                          confirmPassword: e.target.value,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSignUp();
                        }
                      }}
                      tabIndex={3}
                    />
                    <button
                      className={classNames(styles.showHideBtn, {
                        [styles.show]: isShowPassword,
                      })}
                      onClick={() => setIsShowPassword(!isShowPassword)}
                    >
                      {isShowPassword ? (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 576 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                        </svg>
                      ) : (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 640 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
                <div className={styles.formItem}>
                  <button
                    className={styles.signBtn}
                    onClick={handleSignUp}
                    disabled={loading}
                    tabIndex={4}
                  >
                    {loading ? (
                      <Loading height="2" width="2" color="#fafafa" />
                    ) : (
                      "Create Account"
                    )}
                  </button>
                </div>
              </>
            )}
            {currentView === "forgot-password" && (
              <>
                <div className={styles.formItem}>
                  <div className={styles.title}>E-Mail</div>
                  <input
                    type="email"
                    placeholder="Enter your e-mail"
                    value={signInData.email}
                    onChange={(e) => {
                      setSignInData({ ...signInData, email: e.target.value });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleForgotPassword();
                      }
                    }}
                  />
                </div>
                <div className={styles.formItem}>
                  <button
                    className={styles.signBtn}
                    onClick={handleForgotPassword}
                    disabled={loading}
                  >
                    {loading ? (
                      <Loading height="2" width="2" color="#fafafa" />
                    ) : (
                      "Reset Password"
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
          <div className={styles.switchQuestion}>
            <div className={styles.switchText}>
              {currentView === "sign-in"
                ? "Don't have an account?"
                : currentView === "sign-up"
                ? "Already have an account?"
                : currentView === "forgot-password"
                ? "Remembered your password?"
                : undefined}
            </div>
            <div
              className={styles.switchBtn}
              onClick={() =>
                setCurrentView(
                  currentView === "sign-in" ? "sign-up" : "sign-in"
                )
              }
            >
              {currentView === "sign-in"
                ? "Create Your Business on Marul"
                : currentView === "sign-up"
                ? "Sign in to your account"
                : currentView === "forgot-password"
                ? "Sign in to your account"
                : undefined}
            </div>
          </div>
          <div className={styles.downloadSection}>
            <div className={styles.titleSection}>
              <div className={styles.title}>Download Now!</div>
              <div className={styles.subTitle}>
                Manage your business on the go with the Marul app.
              </div>
            </div>
            <div className={styles.storeList}>
              <div className={classNames(styles.storeItem, styles.qrItem)}>
                <div className={styles.storeIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width={100}
                    height={100}
                    viewBox="0 0 2000 2000"
                    x={0}
                    y={0}
                    shapeRendering="crispEdges"
                  >
                    <defs />
                    <rect
                      x={0}
                      y={0}
                      width={2000}
                      height={2000}
                      fill="#ffffff"
                    />
                    <rect
                      x={712}
                      y={200}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={776}
                      y={200}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={840}
                      y={200}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={968}
                      y={200}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1224}
                      y={200}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={776}
                      y={264}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={840}
                      y={264}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={904}
                      y={264}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1032}
                      y={264}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1160}
                      y={264}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={328}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={904}
                      y={328}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={968}
                      y={328}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1160}
                      y={328}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1224}
                      y={328}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={392}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={776}
                      y={392}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={392}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1160}
                      y={392}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={456}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={968}
                      y={456}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1160}
                      y={456}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={776}
                      y={520}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={904}
                      y={520}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={520}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={584}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={840}
                      y={584}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={968}
                      y={584}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={584}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1224}
                      y={584}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={840}
                      y={648}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={648}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1160}
                      y={648}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1224}
                      y={648}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={200}
                      y={712}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={264}
                      y={712}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={328}
                      y={712}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={392}
                      y={712}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={584}
                      y={712}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={712}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={840}
                      y={712}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1032}
                      y={712}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1288}
                      y={712}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1480}
                      y={712}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1544}
                      y={712}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1608}
                      y={712}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1736}
                      y={712}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={264}
                      y={776}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={776}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={776}
                      y={776}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={968}
                      y={776}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={776}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1160}
                      y={776}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1224}
                      y={776}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1288}
                      y={776}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1416}
                      y={776}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1480}
                      y={776}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1736}
                      y={776}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={264}
                      y={840}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={456}
                      y={840}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={584}
                      y={840}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={648}
                      y={840}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1032}
                      y={840}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1544}
                      y={840}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1608}
                      y={840}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1672}
                      y={840}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1736}
                      y={840}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={328}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={392}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={456}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={520}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={840}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={904}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={968}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1032}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1160}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1288}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1352}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1416}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1544}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1608}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1736}
                      y={904}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={200}
                      y={968}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={264}
                      y={968}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={456}
                      y={968}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={584}
                      y={968}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={776}
                      y={968}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1032}
                      y={968}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1160}
                      y={968}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1352}
                      y={968}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1416}
                      y={968}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1480}
                      y={968}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1544}
                      y={968}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1672}
                      y={968}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1736}
                      y={968}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={264}
                      y={1032}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={328}
                      y={1032}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={520}
                      y={1032}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={1032}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={1032}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1160}
                      y={1032}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1224}
                      y={1032}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1288}
                      y={1032}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1416}
                      y={1032}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1544}
                      y={1032}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1672}
                      y={1032}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1736}
                      y={1032}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={264}
                      y={1096}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={456}
                      y={1096}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={584}
                      y={1096}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={1096}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={840}
                      y={1096}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={1096}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1160}
                      y={1096}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1224}
                      y={1096}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1288}
                      y={1096}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1416}
                      y={1096}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1480}
                      y={1096}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1544}
                      y={1096}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1672}
                      y={1096}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1736}
                      y={1096}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={200}
                      y={1160}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={328}
                      y={1160}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={456}
                      y={1160}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={648}
                      y={1160}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={1160}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={776}
                      y={1160}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={840}
                      y={1160}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={904}
                      y={1160}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={1160}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1224}
                      y={1160}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1672}
                      y={1160}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1736}
                      y={1160}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={392}
                      y={1224}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={456}
                      y={1224}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={584}
                      y={1224}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={1224}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={904}
                      y={1224}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={968}
                      y={1224}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1224}
                      y={1224}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1288}
                      y={1224}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1352}
                      y={1224}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1416}
                      y={1224}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1480}
                      y={1224}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1672}
                      y={1224}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={1288}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={840}
                      y={1288}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={968}
                      y={1288}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1032}
                      y={1288}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={1288}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1224}
                      y={1288}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1480}
                      y={1288}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1544}
                      y={1288}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1608}
                      y={1288}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={776}
                      y={1352}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={968}
                      y={1352}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1032}
                      y={1352}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={1352}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1160}
                      y={1352}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1224}
                      y={1352}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1352}
                      y={1352}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1480}
                      y={1352}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1608}
                      y={1352}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1672}
                      y={1352}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1736}
                      y={1352}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={776}
                      y={1416}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={840}
                      y={1416}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={968}
                      y={1416}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1032}
                      y={1416}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={1416}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1160}
                      y={1416}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1224}
                      y={1416}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1480}
                      y={1416}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1672}
                      y={1416}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1736}
                      y={1416}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={840}
                      y={1480}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1032}
                      y={1480}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={1480}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1224}
                      y={1480}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1288}
                      y={1480}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1352}
                      y={1480}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1416}
                      y={1480}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1480}
                      y={1480}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1608}
                      y={1480}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1736}
                      y={1480}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={1544}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={840}
                      y={1544}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1224}
                      y={1544}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1352}
                      y={1544}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1480}
                      y={1544}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1672}
                      y={1544}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1736}
                      y={1544}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={1608}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={776}
                      y={1608}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={904}
                      y={1608}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={968}
                      y={1608}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1032}
                      y={1608}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={1608}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1160}
                      y={1608}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1544}
                      y={1608}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1608}
                      y={1608}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1672}
                      y={1608}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={1672}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={776}
                      y={1672}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={840}
                      y={1672}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={904}
                      y={1672}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={968}
                      y={1672}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1288}
                      y={1672}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1480}
                      y={1672}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1608}
                      y={1672}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1672}
                      y={1672}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1736}
                      y={1672}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={712}
                      y={1736}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={840}
                      y={1736}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={968}
                      y={1736}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1032}
                      y={1736}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1096}
                      y={1736}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1160}
                      y={1736}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1288}
                      y={1736}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1608}
                      y={1736}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1672}
                      y={1736}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <rect
                      x={1736}
                      y={1736}
                      width={64}
                      height={64}
                      fill="#000000"
                    />
                    <svg
                      id="Ebene_1"
                      x={200}
                      y={200}
                      width={448}
                      height={448}
                      viewBox="0 0 699.988 699.986"
                      enableBackground="new 0 0 699.988 699.986"
                      xmlSpace="preserve"
                      shapeRendering="auto"
                    >
                      <path
                        fill="#000000"
                        d="M600.99,0h-100h-99.997h-0.001h-99.997h-99.998h-99.998H1v99.998v99.998v99.998v99.999v99.997v99.998v99.998  h99.999h99.998h99.998h99.997h0.001h99.997h100h99.998v-99.998V499.99v-99.997v-99.999v-99.998V99.998V0H600.99z M600.99,199.996  v99.998v99.999v99.997v99.998h-100h-99.997h-0.001h-99.997h-99.998h-99.998V499.99v-99.997v-99.999v-99.998V99.998h99.998h99.998  h99.997h0.001h99.997h100V199.996z"
                      />
                    </svg>
                    <svg
                      id="Ebene_1"
                      x={200}
                      y={200}
                      width={448}
                      height={448}
                      viewBox="0 0 699.988 699.988"
                      enableBackground="new 0 0 699.988 699.988"
                      xmlSpace="preserve"
                      shapeRendering="auto"
                    >
                      <polygon
                        fill="#000000"
                        points="399.994,199.997 399.992,199.997 299.996,199.997 199.998,199.997 199.998,299.994 199.998,399.994   199.998,499.991 299.996,499.991 399.992,499.991 399.994,499.991 499.99,499.991 499.99,399.994 499.99,299.994 499.99,199.997 "
                      />
                    </svg>
                    <svg
                      id="Ebene_1"
                      x={1352}
                      y={200}
                      width={448}
                      height={448}
                      viewBox="0 0 699.988 699.986"
                      enableBackground="new 0 0 699.988 699.986"
                      xmlSpace="preserve"
                      shapeRendering="auto"
                    >
                      <path
                        fill="#000000"
                        d="M600.99,0h-100h-99.997h-0.001h-99.997h-99.998h-99.998H1v99.998v99.998v99.998v99.999v99.997v99.998v99.998  h99.999h99.998h99.998h99.997h0.001h99.997h100h99.998v-99.998V499.99v-99.997v-99.999v-99.998V99.998V0H600.99z M600.99,199.996  v99.998v99.999v99.997v99.998h-100h-99.997h-0.001h-99.997h-99.998h-99.998V499.99v-99.997v-99.999v-99.998V99.998h99.998h99.998  h99.997h0.001h99.997h100V199.996z"
                      />
                    </svg>
                    <svg
                      id="Ebene_1"
                      x={1352}
                      y={200}
                      width={448}
                      height={448}
                      viewBox="0 0 699.988 699.988"
                      enableBackground="new 0 0 699.988 699.988"
                      xmlSpace="preserve"
                      shapeRendering="auto"
                    >
                      <polygon
                        fill="#000000"
                        points="399.994,199.997 399.992,199.997 299.996,199.997 199.998,199.997 199.998,299.994 199.998,399.994   199.998,499.991 299.996,499.991 399.992,499.991 399.994,499.991 499.99,499.991 499.99,399.994 499.99,299.994 499.99,199.997 "
                      />
                    </svg>
                    <svg
                      id="Ebene_1"
                      x={200}
                      y={1352}
                      width={448}
                      height={448}
                      viewBox="0 0 699.988 699.986"
                      enableBackground="new 0 0 699.988 699.986"
                      xmlSpace="preserve"
                      shapeRendering="auto"
                    >
                      <path
                        fill="#000000"
                        d="M600.99,0h-100h-99.997h-0.001h-99.997h-99.998h-99.998H1v99.998v99.998v99.998v99.999v99.997v99.998v99.998  h99.999h99.998h99.998h99.997h0.001h99.997h100h99.998v-99.998V499.99v-99.997v-99.999v-99.998V99.998V0H600.99z M600.99,199.996  v99.998v99.999v99.997v99.998h-100h-99.997h-0.001h-99.997h-99.998h-99.998V499.99v-99.997v-99.999v-99.998V99.998h99.998h99.998  h99.997h0.001h99.997h100V199.996z"
                      />
                    </svg>
                    <svg
                      id="Ebene_1"
                      x={200}
                      y={1352}
                      width={448}
                      height={448}
                      viewBox="0 0 699.988 699.988"
                      enableBackground="new 0 0 699.988 699.988"
                      xmlSpace="preserve"
                      shapeRendering="auto"
                    >
                      <polygon
                        fill="#000000"
                        points="399.994,199.997 399.992,199.997 299.996,199.997 199.998,199.997 199.998,299.994 199.998,399.994   199.998,499.991 299.996,499.991 399.992,499.991 399.994,499.991 499.99,499.991 499.99,399.994 499.99,299.994 499.99,199.997 "
                      />
                    </svg>
                  </svg>
                </div>
                <div className={styles.storeDetail}>
                  <div className={styles.title}>
                    Scan the QR code with your phone
                  </div>
                  <div className={styles.storeName}>to download the app</div>
                </div>
              </div>
              <div className={styles.columnItem}>
                <div className={styles.storeItem}>
                  <div className={styles.storeIcon}>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth={0}
                      viewBox="0 0 384 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
                    </svg>
                  </div>
                  <div className={styles.storeDetail}>
                    <div className={styles.title}>Download from</div>
                    <div className={styles.storeName}>App Store</div>
                  </div>
                </div>
                <div className={styles.storeItem}>
                  <div className={styles.storeIcon}>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth={0}
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z" />
                    </svg>
                  </div>
                  <div className={styles.storeDetail}>
                    <div className={styles.title}>Download from</div>
                    <div className={styles.storeName}>Google Play</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authorization;
