// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDRA0cdLR2C5UsvuFWvFEks8KHTy9iVv-Y",
  authDomain: "arfe-business.firebaseapp.com",
  projectId: "arfe-business",
  storageBucket: "arfe-business.appspot.com",
  messagingSenderId: "418578171709",
  appId: "1:418578171709:web:455ecb2931f07ffbc4e754",
  measurementId: "G-B4YJKVQK2F",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
