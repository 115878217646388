import ReactLoading from "react-loading";
import styles from "./Loading.module.scss";

type LoadingProps = {
  width?: string;
  height?: string;
  color?: string;
};

const Loading = ({
  width = "5",
  height = "5",
  color = "#0075ff",
}: LoadingProps) => {
  return (
    <div className={styles.loadingContainer}>
      <ReactLoading
        type="spin"
        color={color}
        height={height + "%"}
        width={width + "%"}
      />
    </div>
  );
};

export default Loading;
