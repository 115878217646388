import styles from "./StatusCircle.module.scss";
import classNames from "classnames";

interface StatusCircleProps {
  status: "active" | "draft" | "pending" | undefined;
}

const StatusCircle = (props: StatusCircleProps) => {
  return (
    <div
      className={classNames(styles.statusCircle, {
        [styles.colorActive]: props.status === "active",
        [styles.colorDraft]: props.status === "draft",
        [styles.colorPending]: props.status === "pending",
      })}
    ></div>
  );
};

export default StatusCircle;
