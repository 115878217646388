import { GridRenderCellParams, DataGridProProps } from "@mui/x-data-grid-pro";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";

const targetAudienceView: DataGridProProps = {
  initialState: { pinnedColumns: { left: ["client"] } },
  columns: [
    {
      field: "client",
      headerName: "Client",
      width: 150,
      resizable: false,
      renderCell: (params: GridRenderCellParams) => (
        <CustomComponentCell
          value={params.value.name}
          image={
            params.value.logo
              ? {
                  rounded: "standard",
                  value: params.value.logo,
                }
              : undefined
          }
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <CustomComponentCell value={params.value} status={params.value} />
      ),
    },
    { field: "language", headerName: "Language", width: 150, editable: false },
    { field: "countries", headerName: "Country", width: 150, editable: false },
    {
      field: "startDate",
      type: "string",
      headerName: "Start Date",
      width: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams) =>
        new Date(params.value).toLocaleDateString("tr-TR", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),
    },
  ],
  density: "compact",
  rowHeight: 40,
  getDetailPanelContent: ({ row }) => {
    return (
      <div>
        <p>Custom Component with auto height</p>
        <p>cpm: {row.cpm}</p>
      </div>
    );
  },
  getDetailPanelHeight: () => "auto",
  sx: {
    "& .MuiDataGrid-cell": {
      cursor: "pointer",
    },
  },
};

export default targetAudienceView;
